import { useEffect, useState } from 'react';
import saints from '../../assets/saints.jpg';
import dateTimeUtils from '../../utils/DateTimeUtils';
import assumption from '../../assets/assumption.jpeg';
import ourmotherbirthday from '../../assets/ourmotherbirthday.jpeg';
import nativity from '../../assets/nativity.jpg';
import epiphany from '../../assets/epiphany.jpg';
const images = {
    '11-01': saints,
    '08-15': assumption,
    '09-08': ourmotherbirthday,
    '12-25': nativity,
    '01-06': epiphany
};
export default function useBackgroundImage() {
    const [image, setImage] = useState();
    useEffect(() => {
        const today = dateTimeUtils(undefined).format("YYYY-MM-DD");
        const [_year, month, day] = today.split('-');
        const formattedToday = `${month}-${day}`;
        setImage(images[formattedToday]);
    }, []);
    return [image];
}
