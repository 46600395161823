import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dateTimeUtils, { calculateDaysSince } from '../../../utils/DateTimeUtils';
const START = dateTimeUtils('2024-12-30');
const storageKey = 'boushar.counters';
export default function () {
    const inputRef = React.useRef(null);
    const [counters, setCounters] = React.useState({});
    React.useEffect(() => {
        const storedCounters = localStorage.getItem(storageKey);
        if (storedCounters) {
            setCounters(JSON.parse(storedCounters));
        }
    }, []);
    const addCounter = () => {
        if (inputRef.current) {
            const counterName = inputRef.current.value;
            if (!counters.hasOwnProperty(counterName)) {
                setCounters(Object.assign(Object.assign({}, counters), { [counterName]: 0 }));
                localStorage.setItem(storageKey, JSON.stringify(Object.assign(Object.assign({}, counters), { [counterName]: 0 })));
            }
            inputRef.current.value = '';
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("h1", null, "Daily Counters"),
            React.createElement("input", { type: "text", placeholder: "Enter Counter", ref: inputRef }),
            React.createElement("button", { onClick: addCounter }, "Add Counter"),
            React.createElement("ul", null, Object.entries(counters).map(([name, value]) => (React.createElement("li", { key: name },
                name,
                ": ",
                calculateDaysSince(START.format('YYYY-MM-DD')) - value,
                React.createElement("button", { onClick: () => {
                        const newCounters = Object.assign(Object.assign({}, counters), { [name]: value + 1 });
                        setCounters(newCounters);
                        localStorage.setItem(storageKey, JSON.stringify(newCounters));
                    } }, "Done")))))),
        false && React.createElement("div", null,
            React.createElement("h1", null, "Other Counters"),
            React.createElement(Box, { display: "grid", gridTemplateColumns: "auto auto auto", style: { alignItems: 'center' } },
                React.createElement(Box, null, "Fasting"),
                React.createElement(Box, null,
                    React.createElement(OutlinedInput, { placeholder: "Hours" })),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(ArrowDropUpIcon, null)),
                    React.createElement(Box, null,
                        React.createElement(ArrowDropDownIcon, null))),
                React.createElement(Box, null, "Credit"),
                React.createElement(Box, null,
                    React.createElement(OutlinedInput, { placeholder: "Hours" })),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(ArrowDropUpIcon, null)),
                    React.createElement(Box, null,
                        React.createElement(ArrowDropDownIcon, null))),
                React.createElement(Box, null, "Croissant"),
                React.createElement(Box, null,
                    React.createElement(OutlinedInput, { placeholder: "Hours" })),
                React.createElement(Box, null,
                    React.createElement(Box, null,
                        React.createElement(ArrowDropUpIcon, null)),
                    React.createElement(Box, null,
                        React.createElement(ArrowDropDownIcon, null)))))));
}
