import * as React from "react";
import ProvideAppContext from '../hooks/AppContext';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import ErrorMessage from './ErrorMessage';
import Scheduler from "./Scheduler";
import Drawer from "@mui/material/Drawer";
import { AppBar, Box, CssBaseline, List, ListItemButton, ListItemText, Toolbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import { Weight } from "../weight/Weight";
import { Clock } from "../features/clock/Clock";
import OneThreeFive from "./OneThreeFive";
import { About } from "./About";
import ReLoad from './Reload';
import Location from './Location';
import Counters from "../features/counters/components/Counters";
import BusySignal from "./BusySignal";
import { Plan } from "../onethreefive/components/Plan";
import { Cards } from "../wheel/Cards";
import useBackgroundImage from "../onethreefive/hooks/BackgroundImage";
const drawerWidth = 200;
const ONETHREEFIVE = '1-3-5';
const WHEEL = 'Activities';
const WEIGHT = 'Weight';
const SIGNOUT = 'Sign Out';
const ABOUT = 'About';
const COUNTERS = 'Counters';
const PLAN = 'Plan';
export default function App() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [bShowWeight, setShowWeight] = useState(false);
    const [bOneThreeFive, setOneThreeFive] = useState(true);
    const [bShowAbout, setAbout] = useState(false);
    const [bShowWheel, setShowWheel] = useState(false);
    const [bCounters, setCounters] = useState(false);
    const [bPlan, setPlan] = useState(false);
    const { instance } = useMsal();
    const [image] = useBackgroundImage();
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    const switchContent = (text) => {
        setShowWeight(false);
        setOneThreeFive(false);
        setAbout(false);
        setShowWheel(false);
        setCounters(false);
        setPlan(false);
        switch (text) {
            case ONETHREEFIVE:
                setOneThreeFive(true);
                break;
            case WEIGHT:
                setShowWeight(true);
                break;
            case ABOUT:
                setAbout(true);
                break;
            case SIGNOUT:
                instance.logoutRedirect();
                break;
            case WHEEL:
                setShowWheel(true);
                break;
            case COUNTERS:
                setCounters(true);
                break;
            case PLAN:
                setPlan(true);
        }
        handleDrawerToggle();
    };
    const drawer = (React.createElement(React.Fragment, null,
        React.createElement(Toolbar, null),
        React.createElement(List, null, [ONETHREEFIVE, PLAN, WEIGHT, WHEEL, COUNTERS, ABOUT, SIGNOUT].map((text, index) => (React.createElement(ListItemButton, { key: text },
            React.createElement(ListItemText, { primary: text, onClick: () => switchContent(text) })))))));
    return (React.createElement(ProvideAppContext, null,
        React.createElement(Scheduler, null),
        React.createElement(CssBaseline, null),
        React.createElement(ErrorMessage, null),
        React.createElement(Box, { sx: { flexGrow: 1 } },
            React.createElement(AppBar, { position: "static" },
                React.createElement(Toolbar, null,
                    React.createElement(MenuIcon, { color: 'inherit', sx: { mr: 2 }, onClick: handleDrawerToggle }),
                    React.createElement(Clock, null),
                    React.createElement(ReLoad, null),
                    React.createElement(AuthenticatedTemplate, null,
                        React.createElement(Location, null)),
                    React.createElement(BusySignal, null)))),
        React.createElement(Box, { component: "nav", sx: { width: drawerWidth } },
            React.createElement(Drawer, { open: drawerOpen, onClose: handleDrawerToggle, sx: {
                    display: 'block',
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                } }, drawer)),
        React.createElement(Box, { component: "main", sx: {
                flexGrow: 1, p: 3,
                width: { sm: `calc(100%-${drawerWidth}px)` }
            }, style: {
                backgroundImage: (image != undefined) ? `url(${image})` : ''
            } },
            bShowWeight && React.createElement(Weight, null),
            bOneThreeFive && React.createElement(OneThreeFive, null),
            bShowAbout && React.createElement(About, null),
            bShowWheel && React.createElement(Cards, null),
            bCounters && React.createElement(Counters, null),
            bPlan && React.createElement(Plan, null))));
}
;
